import { IErrorValidation } from './types';

/* Username */

export const getUsernameValidation = (username: string) => {
	let usernameValidationError: IErrorValidation[] = [
		{
			message: 'Has a minimum of 3 characters',
			valid: username.length >= 3
		},
		{
			message: 'Has a maximum of 32 characters',
			valid: username.length <= 32
		},
		{
			message: 'Has only characters, digits or empty spaces',
			valid: !/[!-\/:-@[-`´{-~ºª¨]/.test(username)
		},
		{
			message: `Doesn't start with an empty space`,
			valid: username.at(0) !== ' '
		},
		{
			message: `Doesn't end with an empty space`,
			valid: username.at(username.length - 1) !== ' '
		},
		{
			message: 'Has no more than 1 space in a row',
			valid: !/\s{2}/.test(username)
		}
	];

	return usernameValidationError;
}

export const isUsernameValid = (validation: IErrorValidation[]) => {
	return !validation.find(error => !error.valid);
}

/* E-Mail */

export const isEmailValid = (email: string) => {
	const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return pattern.test(email);
}

/* Password */

export const hasUppercaseLetter = (password: string) => {
	const lowercasedPassword = password.toLowerCase();

	return lowercasedPassword !== password;
}

export const hasLowercaseLetter = (password: string) => {
	const uppercasedPassword = password.toUpperCase();

	return uppercasedPassword !== password;
}

export const getPasswordValidation = (password: string) => {
	let passwordValidationError: IErrorValidation[] = [
		{
			message: 'Has a minimum of 8 characters',
			valid: password.length >= 8
		},
		{
			message: 'Has a maximum of 50 characters',
			valid: password.length <= 50
		},
		{
			message: 'Has a number',
			valid: /\d/.test(password)
		},
		{
			message: 'Has a symbol',
			valid: /[!-\/:-@[-`´{-~ºª¨]/.test(password)
		},
		{
			message: 'Has an uppercase letter',
			valid: hasUppercaseLetter(password)
		},
		{
			message: 'Has a lowercase letter',
			valid: hasLowercaseLetter(password)
		},
		{
			message: `Doesn't start with an empty space`,
			valid: password.at(0) !== ' '
		},
		{
			message: `Doesn't end with an empty space`,
			valid: password.at(password.length - 1) !== ' '
		}
	];

	return passwordValidationError;
}

export const isPasswordValid = (validation: IErrorValidation[]) => {
	return !validation.find(error => !error.valid);
}