import { CheckButton, IconButton } from 'components';
import { useTheme } from 'styled-components';

import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { TaskContainerStyled, TaskTitleFieldStyled } from './Task.styled';
import { ITask } from './types';

export function Task({ onDelete, onSelect, onUnselect, completed: selected, taskId, title: text }: ITask) {
	const theme = useTheme();

	return (
		<TaskContainerStyled>
			<CheckButton
				onSelect={onSelect}
				onUnselect={onUnselect}
				selectedByDefault={selected}
				taskId={taskId}
			/>

			<TaskTitleFieldStyled
				taskId={taskId}
				text={text}
			/>

			<IconButton
				color={theme.colors.danger}
				icon={faTrash}
				onClick={onDelete}
			/>
		</TaskContainerStyled>
	);
}