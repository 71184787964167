import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ $shouldHideOverflow?: boolean; }>`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		transition: all 0.5s;
	}

	html {
		font-size: 20px;
	}
`;