import styled from 'styled-components';

import { IHidden, ITaskListItem } from './types';

export const TaskListContainerStyled = styled.div<IHidden>`
	display: flex;
	flex-direction: column;
	gap: 32px;
	height: 100%;
	padding: 32px;

	${({ $hidden }) => $hidden && `
		padding: 0;
		padding-top: 10px;
		align-items: center;
	`}
`;

export const TaskListTitleContainerStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
`;

export const TaskListTitleStyled = styled.h1<IHidden>`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.accent};
	font-size: 2rem;

	${({ $hidden }) => $hidden && `
		display: none;
	`}
`;

export const TaskListStyled = styled.nav<IHidden>`
	display: ${({ $hidden }) => $hidden ? 'none' : 'flex'};
	flex-direction: column;
	gap: 32px;
	flex-grow: 1;
	overflow-y: auto;
	scrollbar-color: ${({ theme }) => `${theme.colors.accent} ${theme.colors.divider}`};
	scrollbar-width: 16px;
	padding-inline-end: 8px;
`;

export const TaskListItemStyled = styled.li<ITaskListItem>`
	color: ${({ theme }) => theme.colors.hoverText};
	cursor: pointer;
	font-size: 0.8rem;
	list-style: none;

	&:hover {
		padding: ${({ $selected }) => $selected ? '8px' : '8px'};
		background-color: ${({ theme }) => theme.colors.highlightFill};
		border-radius: 8px;
	}

	${({ $selected, theme }) => $selected && `
		background-color: ${theme.colors.highlightFill};
		border-radius: 8px;
		border: 1px solid ${theme.colors.accent};
		font-weight: bold;
		padding: 8px;
	`}
`;

export const TaskUnorderedListStyled = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const TaskListButtonsStyled = styled.div<IHidden>`
	display: ${({ $hidden }) => $hidden ? 'none' : 'flex'};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;

export const HiddenDivStyled = styled.div<IHidden>`
	${({ $hidden }) => $hidden && `
		display: none;
	`}
`;