import { useState } from 'react';

import { FieldContainerStyled, FieldErrorStyled, FieldStyled } from './Field.styled';
import { IField } from './types';

export function Field({ enabled = true, error, onBlur, onValueChanged, placeholder, size = '1x', styleType: style, type = 'text', value }: IField) {
	const [valueText, setValueText] = useState(value);

	const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onValueChanged?.(event.target.value);
		setValueText(event.target.value);
	}

	return (
		<FieldContainerStyled>
			<FieldStyled
				$enabled={enabled}
				$error={!!error?.status}
				$hasValue={valueText.length > 0}
				$size={size}
				$style={style}
				value={value}
				disabled={!enabled}
				onChange={onValueChange}
				placeholder={placeholder}
				type={type}
				onBlur={() => onBlur?.(valueText)}
			/>

			{(error && error.status) && error.text.map((errorText, index) => <FieldErrorStyled key={index} $color={errorText.color || 'danger'}>{errorText.message}</FieldErrorStyled>)}
		</FieldContainerStyled>
	);
}