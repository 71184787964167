import styled from 'styled-components';

export const ProgressStyled = styled.progress`
	width: 100%;
	height: 25px;
	border: none;
	outline: none;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.progress};
	color: ${({ theme }) => theme.colors.success};

	&::-moz-progress-bar, &::-webkit-progress-value {
		background: ${({ theme }) => theme.colors.success};
	}

	&::-webkit-progress-bar {
		background-color: ${({ theme }) => theme.colors.progress};
		border-radius: 8px;
	}

	&::-webkit-progress-value {
		border-radius: 8px;
		background: ${({ theme }) => theme.colors.success};
	}
`;