import styled from 'styled-components';

import { IButtonStyledProps } from './types';

export const ButtonStyled = styled.button<IButtonStyledProps>`
	background-color: transparent;
	border: 1px solid ${({ $color, theme }) => $color === 'submit' ? theme.colors.accent : $color === 'danger' ? theme.colors.danger : theme.colors.success};
	border-radius: 8px;
	color: ${({ $color, theme }) => $color === 'submit' ? theme.colors.accent : $color === 'danger' ? theme.colors.danger : theme.colors.success};
	font-weight: bold;
	height: 50px;
	padding-inline: 16px;
	width: 100%;

	&:hover {
		background-color: ${({ $color, theme }) => $color === 'submit' ? theme.colors.accent : $color === 'danger' ? theme.colors.danger : theme.colors.success};
		color: ${({ theme }) => theme.colors.background};
		cursor: pointer;
	}

	${({ $color, $disabled, theme }) => $disabled && `
		&:hover {
			background-color: transparent;
			color: ${$color === 'submit' ? theme.colors.accent : $color === 'danger' ? theme.colors.danger : theme.colors.success};
			cursor: not-allowed;
		}
	`}
`;