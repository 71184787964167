export const defaultValidations = [
	{
		message: 'Has a minimum of 8 characters',
		valid: false
	},
	{
		message: 'Has a maximum of 50 characters',
		valid: true
	},
	{
		message: 'Has a number',
		valid: false
	},
	{
		message: 'Has a symbol',
		valid: false
	},
	{
		message: 'Has an uppercase letter',
		valid: false
	},
	{
		message: 'Has a lowercase letter',
		valid: false
	},
	{
		message: `Doesn't start with an empty space`,
		valid: true
	},
	{
		message: `Doesn't end with an empty space`,
		valid: true
	}
];