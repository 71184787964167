import styled from 'styled-components';

export const TitleSectionOutterContainer = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.main};
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

export const TitleSectionContainer = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.main};
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: center;
`;

export const TitleSectionTitle = styled.h1`
	color: ${({ theme }) => theme.colors.main};
	font-size: 2rem;
	font-weight: bold;
	margin: 0;
`;

export const TitleSectionDescription = styled.p`
	color: ${({ theme }) => theme.colors.main};
	font-size: 1rem;
	font-weight: 500;
`;