/* Breakpoints */

export const breakpoints: IBreakpoints = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
    xxl: 1440
};

const themeBreakpoint: IThemeBreakpoint = {
	under: (breakpoint: string, rules: string) => `
		@media (max-width: ${breakpoints[breakpoint as keyof IBreakpoints]}px) {
			${rules}
		}
	`,
	between: (minBreakpoint: string, maxBreakpoint: string, rules: string) => `
		@media (min-width: ${breakpoints[minBreakpoint as keyof IBreakpoints]}px) and (max-width: ${breakpoints[maxBreakpoint as keyof IBreakpoints] - 1}px) {
			${rules}
		}
	`,
	above: (breakpoint: string, rules: string) => `
		@media (min-width: ${breakpoints[breakpoint as keyof IBreakpoints]}px) {
			${rules}
		}
	`
};

interface IBreakpoints {
	sm: number;
	md: number;
	lg: number;
	xl: number;
    xxl: number;
}

interface IThemeBreakpoint {
    under: (breakpoint: string, rules: string) => string;
    between: (minBreakpoint: string, maxBreakpoint: string, rules: string) => string;
    above: (breakpoint: string, rules: string) => string;
}

/* Themes */

export const theme: ITheme = {
	breakpoint: themeBreakpoint,
	colors: {
		accent: '#61546F',
		background: '#1E1A22',
		danger: '#A55555',
		divider: '#221E26',
		highlightFill: '#221E26',
		hoverText: '#9588A4',
		main: '#776886',
		progress: '#88A488',
		success: '#A2E5A1'
	}
}

interface ITheme {
	breakpoint: IThemeBreakpoint;

	colors: {
		accent: string;
		background: string;
		danger: string;
		divider: string;
		highlightFill: string;
		hoverText: string;
		main: string;
		progress: string;
		success: string;
	}
}