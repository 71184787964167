import axios from 'axios';

export const axiosConfig = axios.create({
	baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://tasks-backend.wafflus.com'
});

axiosConfig.interceptors.request.use (
	function (config) {
		config.headers.Authorization = sessionStorage.getItem('token');

		return config;
	},
	function (error) {
	  return Promise.reject(error);
	}
);

export const nonAuthedAxiosConfig = axios.create({
	baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://tasks-backend.wafflus.com'
});