import styled from 'styled-components';

export const TaskContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	height: 100%;
`;

export const TaskTitleContainerStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
`;

export const TaskListStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	flex-grow: 1;
	overflow-y: auto;
	scrollbar-color: ${({ theme }) => `${theme.colors.accent} ${theme.colors.divider}`};
	scrollbar-width: 16px;
	padding-inline-end: 8px;
`;

export const TaskListItemStyled = styled.li`
	list-style: none;
`;