import { axiosConfig } from 'api/AxiosConfig';
import { Button, Field, IconButton, Progress, Separator, Task } from 'components';
import { ITask } from 'modules/task-list/types';
import { theme } from 'pages/app/theme';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
	TaskContainerStyled, TaskListItemStyled, TaskListStyled, TaskTitleContainerStyled
} from './Tasks.styled';
import { ITasks } from './types';

export function Tasks({ onTaskListTitleChange, onTaskListDelete, taskListId, taskListTitle, tasks }: ITasks) {
	const [oldTaskListTitle, setOldTaskListTitle] = useState(taskListTitle);
	const [currentTaskListTitle, setCurrentTaskListTitle] = useState(taskListTitle);
	const [currentTasks, setCurrentTasks] = useState(tasks);
	const [progress, setProgress] = useState(tasks.filter(task => task.completed).length);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setCurrentTasks(tasks);
	}, [tasks]);

	const onTaskListTitleChanged = (newTaskTitle: string) => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			return;
		}

		if (newTaskTitle === oldTaskListTitle) {
			return;
		}

		axiosConfig.put(`/lists/${taskListId}`, {
			title: newTaskTitle
		})
			.then(() => {
				setOldTaskListTitle(newTaskTitle);

				onTaskListTitleChange(newTaskTitle, taskListId);
			})
			.catch(() => {
				setCurrentTaskListTitle(oldTaskListTitle);
			});
	}

	const onTaskListDeleted = () => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			return;
		}
		
		axiosConfig.delete(`/lists/${taskListId}`)
			.then(() => {
				onTaskListDelete(taskListId);
			})
			.catch(() => {});
	}

	const onTaskAdd = () => {
		axiosConfig.post(`tasks`, { title: 'Task', taskListId })
			.then(response => {
				const data: ITask = response.data;

				setCurrentTasks(previousTasks => [...previousTasks, {
					onDelete: () => {},
					onSelect: () => {},
					onUnselect: () => {},
					completed: data.completed,
					taskListId,
					taskId: data.id.toString(),
					title: data.title
				}]);
			})
			.catch(error => {
			});
	};

	const onTaskDelete = (id: string, wasCompleted: boolean) => {
		axiosConfig.delete(`tasks/${id}`)
			.then(response => {
				setCurrentTasks(previousTasks => previousTasks.filter(task => task.taskId !== id));

				if (wasCompleted) {
					setProgress(previousProgress => previousProgress - 1);
				}
			})
			.catch(() => {});
	}

	useEffect(() => {
		setCurrentTaskListTitle(taskListTitle);
	}, [taskListTitle]);

	useEffect(() => {
		setCurrentTasks(tasks);
		setProgress(tasks.filter(task => task.completed).length);
	}, [tasks]);

	useEffect(() => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			navigate('/');

			return;
		}
	}, [navigate]);

	useEffect(() => {
		sessionStorage.removeItem('fetched');

		if (location.state === null) {
			navigate('/welcome-back', { replace: true });
		}
		
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (!sessionStorage.getItem('fetched')) {
				navigate('/welcome-back', { replace: true });
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [location, navigate]);

	if (taskListId === '-1') {
		return <></>;
	}

	return (
		<TaskContainerStyled>
			<TaskTitleContainerStyled>
				<Field
					placeholder='Task List Name'
					styleType='free'
					value={currentTaskListTitle}
					onBlur={onTaskListTitleChanged}
					onValueChanged={value => setCurrentTaskListTitle(value)}
					size='2x'
				/>

				<IconButton
					color={theme.colors.danger}
					icon={faTrash}
					onClick={onTaskListDeleted}
				/>
			</TaskTitleContainerStyled>

			<Button
				onClick={onTaskAdd}
				text="+"
			/>

			<Separator />

			<TaskListStyled>
				<ul>
					{currentTasks.map(task => (
						<TaskListItemStyled key={task.taskId}>
							<Task
								onDelete={() => onTaskDelete(task.taskId, task.completed)}
								onSelect={() => setProgress(previousProgress => previousProgress + 1)}
								onUnselect={() => setProgress(previousProgress => previousProgress - 1)}
								completed={task.completed}
								taskListId={task.taskListId}
								taskId={task.taskId}
								title={task.title}
							/>
						</TaskListItemStyled>
					))}
				</ul>
			</TaskListStyled>

			<Progress
				maximumValue={currentTasks.length}
				value={progress}
			/>
		</TaskContainerStyled>
	);
}