import styled from 'styled-components';

import { ICheckButtonStyles } from './types';

export const CheckButtonStyled = styled.button<ICheckButtonStyles>`
	background: transparent;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.accent};
	height: 40px;
	min-height: 40px;
	min-width: 40px;
	outline: none;
	width: 40px;

	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.progress};
	}

	${({ $selected, theme }) => $selected && `
		border: 1px solid ${theme.colors.success};
	`}
`;