import { axiosConfig } from 'api/AxiosConfig';
import { TitleSection } from 'components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WelcomeErrorStyled } from './Welcome.styled';

export function Welcome() {
	const [apiError, setApiError] = useState('');
	const [message, setMessage] = useState(`We're retrieving your tasks list...`);
	
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			navigate('/login');
			
			return;
		}

		const longLoadingTimeout = setTimeout(() => {
			setApiError(`We're taking a while to retrieve your tasks.`);
		}, 10000);

		const veryLongLoadingTimeout = setTimeout(() => {
			setApiError(`There's likely a problem with our API. Please try again later.`);
		}, 25000);

		axiosConfig.get(`/lists`)
			.then(response => {
				setApiError('');

				setTimeout(() => {
					setMessage(`We're redirecting you...`);
				}, 500);
				
				sessionStorage.setItem('fetched', '1');

				setTimeout(() => {
					navigate('/tasks', { state: { tasks: response.data } });
				}, 2000);
			})
			.catch(() => {
				clearTimeout(longLoadingTimeout);
				clearTimeout(veryLongLoadingTimeout);

				sessionStorage.removeItem('fetched');

				setApiError(`We're having trouble retrieving your tasks, make sure you're connected to the internet and authenticated.`);
			});

		return () => {
			clearTimeout(longLoadingTimeout);
			clearTimeout(veryLongLoadingTimeout);
			setApiError('');
			setMessage(`We're retrieving your tasks list...`);
		};
	}, [location.state, navigate]);
	
	return (
		<>
			<TitleSection
				description={message}
				title={`Welcome.`}
				extras={
					<>
						<FontAwesomeIcon icon={faCircleNotch} spin size='2x' />

						{!!apiError && <WelcomeErrorStyled>{apiError}</WelcomeErrorStyled>}
					</>
				}
			/>
		</>
	);
}