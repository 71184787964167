import { TextArea } from 'components/text-area';
import styled from 'styled-components';

export const TaskStyled = styled.ul``;

export const TaskContainerStyled = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;
	height: 50px;
	justify-content: space-between;
`;

export const TaskTitleFieldStyled = styled(TextArea)`
	flex-grow: 1;
`;