import styled from 'styled-components';

import { IContainerStyled } from './types';

export const Main = styled.main`
	background-color: ${({ theme }) => theme.colors.background};
	padding: 32px;
	display: flex;
	gap: 20px;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	grid-area: main;
	width: 100%;
	max-width: 600px;
`;

export const Sidebar = styled.aside`
	grid-area: sidebar;
	border-inline-end: 2px solid ${({ theme }) => theme.colors.divider};
	width: 100%;
`;

export const Footer = styled.footer`
	grid-area: footer;
	border-block-start: 2px solid ${({ theme }) => theme.colors.divider};
`;

export const Container = styled.div<IContainerStyled>`
	display: grid;
	grid-template-areas:
		"main"
		"footer";
	grid-template-rows: 1fr 50px;
	grid-template-columns: 1fr;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	background-color: ${({ theme }) => theme.colors.background};

	${({ $isInTasks, $toggled }) => $isInTasks && `
		grid-template-areas:
			"sidebar main"
			"sidebar footer";
		grid-template-rows: 1fr 50px;
		grid-template-columns: minmax(0, ${$toggled ? '300px' : '60px'}) 1fr;
	`}
`;