import styled from 'styled-components';

import { IFieldErrorStyled, IFieldStyled } from './types';

export const FieldStyled = styled.input<IFieldStyled>`
	background: transparent;
	color: ${({ theme }) => theme.colors.accent};
	font-size: ${({ $size }) => $size === '1x' ? '1rem' : '2rem'};
	height: ${({ $size }) => $size === '1x' ? '60px' : '1.5em'};
	outline: 0;
	width: 100%;

	&:hover, &:focus {
		color: ${({ theme }) => theme.colors.main};
	}

	${({ $hasValue, theme }) => $hasValue && `
		color: ${theme.colors.main};
	`}

	${({ $hasValue, $style, theme }) => $style === 'contained' && `
		border-radius: 8px;
		border: 2px solid ${theme.colors.accent};
		padding-inline: 20px;
		
		${$hasValue && `
			border: 2px solid ${theme.colors.main};
		`}

		&:hover, &:focus {
			border: 2px solid ${theme.colors.main};
		}
	`}

	${({ $style }) => $style === 'free' && `
		border: none;
	`}

	${({ $error, $hasValue, theme }) => $error && `
		border: 2px solid ${theme.colors.danger};
		color: ${theme.colors.danger};
		
		${$hasValue && `
			border: 2px solid ${theme.colors.danger};
			color: ${theme.colors.danger};
		`}

		&:hover, &:focus {
			border: 2px solid ${theme.colors.danger};
			color: ${theme.colors.danger};
		}
	`}

	${({ $enabled, $hasValue, theme }) => !$enabled && `
		border: 2px solid ${theme.colors.accent}80;
		color: ${theme.colors.accent}80;
		cursor: not-allowed;
		
		${$hasValue && `
			border: 2px solid ${theme.colors.accent}80;
			color: ${theme.colors.accent}80;
		`}

		&:hover, &:focus {
			border: 2px solid ${theme.colors.accent}80;
			color: ${theme.colors.accent}80;
		}
	`}
`;

export const FieldErrorStyled = styled.span<IFieldErrorStyled>`
	color: ${({ $color, theme }) => $color === 'danger' ? theme.colors.danger : theme.colors.success};
	padding-inline-start: 16px;
`;

export const FieldContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
`;