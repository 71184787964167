import { axiosConfig, nonAuthedAxiosConfig } from 'api/AxiosConfig';
import { AxiosInstance } from 'axios';
import { ReactNode, createContext, useContext } from 'react';

const AuthContext = createContext<IAuthContext>({});

export default AuthContext;

export const useAuth = () => useContext(AuthContext);

export interface IAuthContext {
	axiosConfig?: AxiosInstance;
	login?: (email: string, password: string) => Promise<IStatus>;
	logout?: () => void;
	register?: (email: string, password: string, repeatPassword: string) => Promise<IStatus>;
}

interface IStatus {
	status: number;
	successful: boolean;
}

interface IAuthProviderProps {
	children: ReactNode | ReactNode[];
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {

	const login = async (email: string, password: string) => {
		try {
			const response = await nonAuthedAxiosConfig.post('/login', { email, password });
			
			const authToken = `Bearer ${response.data.token}`;

			sessionStorage.setItem('token', authToken);
			sessionStorage.setItem('user', response.data.email);

			axiosConfig.interceptors.request.use(
				config => {
					config.headers.Authorization = authToken;

					return config;
				}
			);

			return { status: response.request.status, successful: true };
		} catch (error: any) {
			logout();

			return { status: error?.response?.data?.statusCode || 500, successful: false };
		}
	}

	const logout = () => {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
	}

	const register = async (email: string, password: string, repeatPassword: string) => {
		try {
			const response = await nonAuthedAxiosConfig.post('/register', { email, password, repeatPassword });

			return { status: response.request.status, successful: true };
		} catch (error: any) {
			logout();

			return { status: error?.response?.data?.statusCode || 500, successful: false };
		}
	}

	const providerValue = {
		axiosConfig,
		login,
		logout,
		register
	};

	return (
		<AuthContext.Provider value={providerValue}>
			{children}
		</AuthContext.Provider>
	);
}