import { Button, Field, TitleSection } from 'components';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IErrorValidation } from '../types';
import { getPasswordValidation, isEmailValid, isPasswordValid } from '../utility';
import { FormStyled, RegisterStyled } from './Register.styled';

export function Register() {
	const location = useLocation();
	const navigate = useNavigate();
	const auth = useAuth();

	const [email, setEmail] = useState(location.state?.email || '');
	const [password, setPassword] = useState(location.state?.password || '');
	const [repeatedPassword, setRepeatedPassword] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordErrors, setPasswordErrors] = useState<IErrorValidation[]>([
		{
			message: 'Has a minimum of 8 characters',
			valid: false
		},
		{
			message: 'Has a maximum of 32 characters',
			valid: true
		},
		{
			message: 'Has a number',
			valid: false
		},
		{
			message: 'Has a symbol',
			valid: false
		},
		{
			message: 'Has an uppercase letter',
			valid: false
		},
		{
			message: 'Has a lowercase letter',
			valid: false
		},
		{
			message: `Doesn't start with an empty space`,
			valid: true
		},
		{
			message: `Doesn't end with an empty space`,
			valid: true
		}
	]);
	const [repeatedPasswordErrors, setRepeatedPasswordErrors] = useState<IErrorValidation[]>([
		{
			message: 'Has a minimum of 8 characters',
			valid: false
		},
		{
			message: 'Has a maximum of 32 characters',
			valid: false
		},
		{
			message: 'Has a number',
			valid: false
		},
		{
			message: 'Has a symbol',
			valid: false
		},
		{
			message: 'Has an uppercase letter',
			valid: false
		},
		{
			message: 'Has a lowercase letter',
			valid: false
		},
		{
			message: `Doesn't start with an empty space`,
			valid: true
		},
		{
			message: `Doesn't end with an empty space`,
			valid: true
		},
		{
			message: 'Is the same as the password',
			valid: repeatedPassword === password
		}
	]);
	const [fetchingData, setFetchingData] = useState(false);

	useEffect(() => {
		onEmailChanged(location.state?.email || '');
		onPasswordChanged(location.state?.password || '');
		setFetchingData(false);
	}, [location.state?.email, location.state?.password]);

	useEffect(() => {
		if (sessionStorage.getItem('user') && sessionStorage.getItem('token')) {
			navigate('/welcome-back');
		}
	}, [navigate]);

	const onEmailChanged = (newEmail: string) => {
		setEmailError(isEmailValid(newEmail) ? '' : 'A valid e-mail is required.');

		setEmail(newEmail);
	}

	const onPasswordChanged = (newPassword: string) => {
		const validation = getPasswordValidation(newPassword);

		setPasswordErrors(validation);

		const repeatedPasswordValidation = getPasswordValidation(repeatedPassword);

		repeatedPasswordValidation.push({
			message: 'Is the same as the password',
			valid: repeatedPassword === newPassword
		});

		setRepeatedPasswordErrors(repeatedPasswordValidation);

		setPassword(newPassword);
	}

	const onRepeatedPasswordChanged = (newRepeatedPassword: string) => {
		const validation = getPasswordValidation(newRepeatedPassword);

		validation.push({
			message: 'Is the same as the password',
			valid: newRepeatedPassword === password
		});

		setRepeatedPasswordErrors(validation);

		setRepeatedPassword(newRepeatedPassword);
	}

	const onGo = async () => {
		if (!email || !password || !repeatedPassword || !isEmailValid(email) || !isPasswordValid(passwordErrors) || !isPasswordValid(repeatedPasswordErrors) || fetchingData) {
			return;
		}

		setFetchingData(true);

		const registerResponse = await auth.register?.(email, password, repeatedPassword);

		if (registerResponse?.successful) {
			const loginResponse = await auth.login?.(email, password);

			if (loginResponse?.successful) {
				navigate('/welcome');
			} else {
				navigate('/login', { state: { email, password } });
			}
		} else {
			setFetchingData(false);

			let error = '';

			switch (registerResponse?.status) {
				case 400: {
					error = 'The passwords need to be equal.';

					break;
				}

				case 406: {
					error = 'The sent passwords are invalid.';

					break;
				}

				case 409: {
					error = 'This e-mail seems to already exist.';

					break;
				}

				default: {
					error = 'There has been a problem while registering this account.'
				}
			}
			
			setEmailError(error);
		}
	}

	const onBack = () => {
		navigate('/login', { state: { email, password }});
	}

	return (
		<RegisterStyled>
			<TitleSection
				description={`seems like you're new here`}
				title='Creating an Account'
			/>

			<FormStyled>
				<Field
					placeholder='E-Mail'
					styleType='contained'
					value={email}
					onValueChanged={(value) => onEmailChanged(value)}
					type='email'
					error={{
						status: email.length > 0 && !!emailError,
						text: [{ message: emailError }]
					}}
				/>

				<Field
					placeholder='Password'
					styleType='contained'
					value={password}
					onValueChanged={(value) => onPasswordChanged(value)}
					type='password'
					error={{
						status: password.length > 0 && !isPasswordValid(passwordErrors),
						text: passwordErrors.map(error => ({ message: error.message, color: error.valid ? 'success' : 'danger' }))
					}}
				/>

				<Field
					placeholder='Repeat Password'
					styleType='contained'
					value={repeatedPassword}
					onValueChanged={(value) => onRepeatedPasswordChanged(value)}
					type='password'
					error={{
						status: repeatedPassword.length > 0 && !isPasswordValid(repeatedPasswordErrors),
						text: repeatedPasswordErrors.map(error => ({ message: error.message, color: error.valid ? 'success' : 'danger' }))
					}}
				/>

				<Button
					onClick={onGo}
					text='GO'
					disabled={!email || !password || !repeatedPassword || !isEmailValid(email) || !isPasswordValid(passwordErrors) || !isPasswordValid(repeatedPasswordErrors) || fetchingData}
				/>
			</FormStyled>

			<Button
				color='danger'
				onClick={onBack}
				text='BACK'
			/>
		</RegisterStyled>
	);
}