import {
	TitleSectionContainer, TitleSectionDescription, TitleSectionOutterContainer, TitleSectionTitle
} from './TitleSection.styled';
import { ITitleSection } from './types';

export function TitleSection({ description, extras, title }: ITitleSection) {
	return (
		<TitleSectionOutterContainer>
			<TitleSectionContainer>
				<TitleSectionTitle>{title}</TitleSectionTitle>

				<TitleSectionDescription>{description}</TitleSectionDescription>
			</TitleSectionContainer>

			{!!extras && extras}
		</TitleSectionOutterContainer>
	);
}