import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButtonStyled } from './IconButton.styled';
import { IIconButton } from './types';

export function IconButton({ color, icon, onClick }: IIconButton) {
	return(
		<IconButtonStyled
			onClick={onClick}
		>
			<FontAwesomeIcon icon={icon} color={color} />
		</IconButtonStyled>
	);
}