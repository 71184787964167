import './App.css';

import { ITask as ITaskType } from 'components/task/types';
import { AuthProvider, useAuth } from 'context/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { TaskList } from 'modules/task-list';
import { ITaskList } from 'modules/task-list/types';
import { Login } from 'pages/account/login/Login';
import { Register } from 'pages/account/register/Register';
import { WelcomeBack } from 'pages/account/welcome-back/WelcomeBack';
import { Welcome } from 'pages/account/welcome/Welcome';
import { Tasks } from 'pages/tasks/Tasks';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles';

import { Container, Footer, Main, Sidebar } from './App.styled';
import { theme } from './theme';

function App() {
	const location = useLocation();
	const auth = useAuth();

	const [mainTasks, setMainTasks] = useState<ITaskList[]>(location.state?.tasks || []);
	const [tasks, setTasks] = useState<ITaskType[]>([]);
	const [currentTaskList, setCurrentTaskList] = useState<ITaskList | null>(null);
	const [toggled, setToggled] = useState<boolean>(true);

	const isTasksRoute = useMatch('/tasks');
	
	useEffect(() => {
		setMainTasks(location.state?.tasks || []);
	}, [location.state]);

	const onTaskListSelect = (taskList: ITaskList) => {
		const convertedTasks: ITaskType[] = taskList.tasks.map(task => ({
			onDelete: () => {},
			onSelect: () => {},
			onUnselect: () => {},
			completed: task.completed,
			taskListId: taskList.id.toString(),
			taskId: task.id.toString(),
			title: task.title
		}));

		setTasks(convertedTasks);
		setCurrentTaskList(taskList);
	}

	const onTaskNameChange = (name: string, id: string) => {
		const newTasks = mainTasks.map(task => task);

		const updatedTask = newTasks.find(task => task.id.toString() === id);

		if (updatedTask) {
			updatedTask.name = name;
		}

		setMainTasks(newTasks);
	}

	const onTaskDelete = (id: string) => {
		setMainTasks(previousTasks => previousTasks.filter(task => task.id.toString() !== id));

		setCurrentTaskList(null);
	}

	useEffect(() => {
		const token = sessionStorage.getItem('token');

		if (!token) {
			return;
		}

		const decodedToken = jwtDecode(token);

		if (decodedToken.exp && decodedToken.exp * 1000 < new Date().getTime()) {
			auth.logout?.();
			
			setMainTasks([]);
			setTasks([]);
			setCurrentTaskList(null);
		}
	}, [auth, location]);

  return (
	<ThemeProvider theme={theme}>
		<GlobalStyles />
		
		<AuthProvider>
			<Container
				$isInTasks={!!isTasksRoute}
				$toggled={toggled}
			>
				{(!!isTasksRoute) && (
					<Sidebar>
						<Routes>
							<Route path='tasks' element={
								<TaskList
									taskLists={mainTasks}
									onCollapsed={() => setToggled(previousToggled => !previousToggled)}
									onTaskListAdd={task => setMainTasks(previousMainTasks => [...previousMainTasks, task])}
									onTaskListSelect={task => onTaskListSelect(task)}
								/>
							} />
						</Routes>
					</Sidebar>
				)}

				<Main>
					<Routes>
						<Route path='/'>
							<Route index element={<Navigate to={sessionStorage.getItem('user') && sessionStorage.getItem('token') ? '/welcome-back' : '/login'} replace />} />
							<Route path='login' element={<Login />} />
							<Route path='register' element={<Register />} />
							<Route path='welcome-back' element={<WelcomeBack />}
							/>
							<Route path='welcome' element={<Welcome />}
							/>
							<Route path='tasks' element={
								<Tasks
									onTaskListDelete={onTaskDelete}
									onTaskListTitleChange={onTaskNameChange}
									taskListId={(currentTaskList?.id || -1).toString()}
									taskListTitle={(currentTaskList?.name || '').toString()}
									tasks={tasks}
								/>
							} />
						</Route>
						
					</Routes>
				</Main>

				<Footer></Footer>
			</Container>
		</AuthProvider>
	</ThemeProvider>
  );
}

export default App;
