import { ProgressStyled } from './Progress.styled';
import { IProgress } from './types';

export function Progress({ maximumValue, value }: IProgress) {
	return (
		<ProgressStyled
			max={maximumValue}
			value={value}
		/>
	);
}