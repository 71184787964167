import { axiosConfig } from 'api/AxiosConfig';
import { ITask } from 'modules/task-list/types';
import { useState } from 'react';

import { CheckButtonStyled } from './CheckButton.styled';
import { ICheckButton } from './types';

export function CheckButton({ onSelect, onUnselect, selectedByDefault, taskId }: ICheckButton) {
	const [selected, setSelected] = useState(selectedByDefault);
	
	const onClick = () => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			return;
		}

		axiosConfig.put(`/tasks/${taskId}/complete`)
			.then(response => {
				const data: ITask = response.data;

				setSelected(data.completed);

				data.completed ? onSelect() : onUnselect();
				
			})
			.catch(error => {
			});
	}

	return (
		<CheckButtonStyled
			$selected={selected}
			onClick={onClick}
		/>
	);
}