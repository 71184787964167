import { axiosConfig } from 'api/AxiosConfig';
import { useState } from 'react';

import { TextAreaStyled } from './TextArea.styled';
import { ITextArea } from './types';

export function TextArea({ taskId, text }: ITextArea) {
	const [currentText, setCurrentText] = useState(text);
	const [textOnFocus, setTextOnFocus] = useState(text);

	const onFocus = () => {
		setTextOnFocus(currentText);
	}

	const onBlur = () => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			setCurrentText(textOnFocus);

			return;
		}

		if (currentText.length === 0) {
			setCurrentText(textOnFocus);

			return;
		}

		axiosConfig.put(`/tasks/${taskId}`, {
			title: currentText
		})
			.then(response => {
			})
			.catch(error => {
				setCurrentText(textOnFocus);
			});
	}

	return (
		<TextAreaStyled
			onFocus={onFocus}
			onBlur={onBlur}
			value={currentText}
			onInput={event => setCurrentText(event.currentTarget.value)}
		>
			{text}
		</TextAreaStyled>
	);
}