import styled from 'styled-components';

export const IconButtonStyled = styled.button`
	background-color: ${({ theme }) => theme.colors.highlightFill};
	border-radius: 50%;
	border: none;
	height: 40px;
	min-height: 40px;
	min-width: 40px;
	width: 40px;

	&:hover {
		cursor: pointer;
		height: 50px;
		min-height: 50px;
		min-width: 50px;
		width: 50px;
	}
`;