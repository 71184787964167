import { axiosConfig } from 'api/AxiosConfig';
import { Button, IconButton, Separator } from 'components';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {
	HiddenDivStyled, TaskListButtonsStyled, TaskListContainerStyled, TaskListItemStyled,
	TaskListStyled, TaskListTitleContainerStyled, TaskListTitleStyled, TaskUnorderedListStyled
} from './TaskList.styled';
import { ITaskList, ITaskListProps } from './types';

export function TaskList({ onCollapsed, onTaskListAdd, onTaskListSelect, taskLists }: ITaskListProps) {
	const [collapsed, setCollapsed] = useState(false);
	const [selectedTask, setSelectedTask] = useState<ITaskList | null>(null);
	const [fetchingData, setFetchingData] = useState(false);

	const theme = useTheme();
	const auth = useAuth();
	const navigate = useNavigate();

	if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
		return <></>;
	}

	const onLogout = (): void => {
		auth.logout?.();
		
		setSelectedTask(null);
		onTaskListSelect({ id: -1, name: '', tasks: [] });
		setFetchingData(false);

		navigate('/', { replace: true });
	};

	const onTaskAdded = async () => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			return;
		}

		setFetchingData(true);

		await axiosConfig.post(`/lists`, {
			title: 'Task List'
		})
			.then(response => {
				const data: ITaskList = response.data;

				onTaskListAdd(data);
			})
			.catch(() => {})
			.finally(() => setFetchingData(false));
	}

	const onTaskSelected = async (task: ITaskList) => {
		if (!sessionStorage.getItem('user') || !sessionStorage.getItem('token')) {
			return;
		}

		await axiosConfig.get(`/lists/${task.id}`)
			.then(response => {
				const data: ITaskList = response.data;

				setSelectedTask(data);

				onTaskListSelect(data);
			})
			.catch(() => {});
	}

	const onCollapse = () => {
		setCollapsed(previousCollapsed => !previousCollapsed);
		
		onCollapsed();
	}

	return (
		<TaskListContainerStyled $hidden={collapsed}>
			<TaskListTitleContainerStyled>
				<TaskListTitleStyled $hidden={collapsed}>
					Tasks
				</TaskListTitleStyled>

				<IconButton
					color={theme.colors.accent}
					icon={collapsed ? faArrowRight : faArrowLeft}
					onClick={onCollapse}
				/>
			</TaskListTitleContainerStyled>

			<HiddenDivStyled $hidden={collapsed}>
				<Button
					onClick={onTaskAdded}
					disabled={fetchingData}
					text='+'
				/>
			</HiddenDivStyled>

			<HiddenDivStyled $hidden={collapsed}>
				<Separator />
			</HiddenDivStyled>

			<TaskListStyled $hidden={collapsed}>
				<TaskUnorderedListStyled>
					{taskLists.map(taskList => (
						<TaskListItemStyled
							$selected={taskList.id === selectedTask?.id}
							key={taskList.id}
							onClick={() => onTaskSelected(taskList)}
						>
							{taskList.name}
						</TaskListItemStyled>
					))}
				</TaskUnorderedListStyled>
			</TaskListStyled>

			<TaskListButtonsStyled $hidden={collapsed}>
				<Button
					onClick={onLogout}
					text="LOGOUT"
					color='danger'
				/>
			</TaskListButtonsStyled>
		</TaskListContainerStyled>
	);
}