import styled from 'styled-components';

export const TextAreaStyled = styled.textarea`
	background: transparent;
	border: none;
	color: ${({ theme }) => theme.colors.accent};
	font-size: 1.2rem;
	max-height: 125px;
	min-height: 30px;
	outline: 0;
	overflow-y: hidden;
	padding-top: 46px;
	resize: none;
	width: 100%;

	&:hover, &:focus {
		color: ${({ theme }) => theme.colors.main};
		overflow-y: auto;
	}
`;