import { Button, Field, TitleSection } from 'components';
import { useAuth } from 'context/AuthContext';
import { defaultValidations } from 'pages/account/login/validations';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IErrorValidation } from '../types';
import { getPasswordValidation, isEmailValid, isPasswordValid } from '../utility';
import { FormStyled, LoginStyled } from './Login.styled';

export function Login() {
	const location = useLocation();
	const auth = useAuth();

	const [email, setEmail] = useState(location.state?.email || '');
	const [password, setPassword] = useState(location.state?.password || '');
	const [emailError, setEmailError] = useState('');
	const [passwordErrors, setPasswordErrors] = useState<IErrorValidation[]>(defaultValidations);
	const [fetchingData, setFetchingData] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		onEmailChanged(location.state?.email || '');
		onPasswordChanged(location.state?.password || '');
		setFetchingData(false);
	}, [location.state?.email, location.state?.password]);

	useEffect(() => {
		if (sessionStorage.getItem('user') && sessionStorage.getItem('token')) {
			navigate('/welcome-back');
		}
	}, [navigate]);

	const onEmailChanged = (newEmail: string) => {
		setEmailError(isEmailValid(newEmail) ? '' : 'A valid e-mail is required.');

		setEmail(newEmail);
	};

	const onPasswordChanged = (newPassword: string) => {
		const validation = getPasswordValidation(newPassword);

		setPasswordErrors(validation);

		setPassword(newPassword);
	}

	const onGo = async () => {
		if (!email || !password || !isEmailValid(email) || !isPasswordValid(passwordErrors) || fetchingData) {
			return;
		}

		setFetchingData(true);

		const loginResponse = await auth.login?.(email, password);

		if (loginResponse?.successful) {
			navigate('/welcome-back');
		} else {
			if (loginResponse?.status === 404) {
				navigate('/register', { state: { email, password }});

				return;
			}

			let error = 'The credentials for this e-mail did not match.';

			if (loginResponse?.status === 406) {
				error = 'The sent password is invalid.'
			}

			if (loginResponse?.status === 500) {
				error = 'There has been an internal problem, something must be down. Try again later.'
			}

			setFetchingData(false);

			setEmailError(error);
		}
	}

	return (
		<LoginStyled>
			<TitleSection
				description='manage the simple way'
				title='Tasks'
			/>

			<FormStyled>
				<Field
					placeholder='E-Mail'
					styleType='contained'
					value={email}
					onValueChanged={(value) => onEmailChanged(value)}
					type='email'
					error={{
						status: email.length > 0 && !!emailError,
						text: [{ message: emailError }]
					}}
				/>

				<Field
					placeholder='Password'
					styleType='contained'
					value={password}
					onValueChanged={(value) => onPasswordChanged(value)}
					type='password'
					error={{
						status: password.length > 0 && !isPasswordValid(passwordErrors),
						text: passwordErrors.map(error => ({ message: error.message, color: error.valid ? 'success' : 'danger' }))
					}}
				/>

				<Button
					disabled={!email || !password || !isEmailValid(email) || !isPasswordValid(passwordErrors) || fetchingData}
					onClick={onGo}
					text='GO'
				/>

				<TitleSection
					description={`If your account does not exist, you'll be taken to the register page.`}
					title=''
				/>
			</FormStyled>
		</LoginStyled>
	);
}